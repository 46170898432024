import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { toast } from "react-toastify";
import { axiosApi } from "../../helpers/axios";

const initialStates = {
  error: {},
  loading: false,
  getLocationData: null,
  getSubLocationData: null,
  getFacilityData: null,
  getOperatorData: null,
  getSubOperatorData: null,
  getCMSProjectData: null,
  getCityData: null,
  getOpeningHoursData: null,
  getRFIDCardData: null,
  getEVSETypeData: null,
  getStationData: null,
  getStationDetailsData: null,
  getUserData: null,
  getChargerGroupCapacityData: null,
  getSessionLogsData: null,
  getConfigurationData: null,
  triggerMessageData: null,
  allSessionsData: null,
  overviewAllData: null,
  locationIndividualData: null,
  getDiagnosticsData: null,
  overviewData: null,
  overviewPerData: null,
  avgSessionLengthData: null,
  overviewGraphData: null,
  availablityStatusData: null,
  websocketOverviewData: null,
};


/**
* @param {getUserAPI}
*/
export const getUserAPI = () =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post(`/cms/all_users`, {
        "company_name": "Durapower"
      });
      if (response) {
        dispatch(
          cmsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "getUserData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };

/**
* @param {updateFirmWareAPI}
*/
export const updateFirmWareAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post(`/cms/update_firware`, payload);
      if (response) {
        dispatch(
          cmsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };

/**
 * @param {getCMSProjectAPI}
 */
export const getCMSProjectAPI = () =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.get(`/cms/project_list/user_id/${localStorage.getItem("user_id")}/`, null);
      if (response) {
        dispatch(
          cmsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "getCMSProjectData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };

/**
 * @param {addLocationAPI}
 */
export const addLocationAPI = ({ payload, projId }) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post(`/cms/charge_op_location?projId=${projId}`, payload);
      if (response) {
        dispatch(
          cmsSetState([{ key: "loading", value: false }])
        );
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };


/**
* @param {putLocationAPI}
*/
export const putLocationAPI = ({ projId, id, payload }) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.put(`/cms/charge_op_location/${id}/?projId=${projId}`, payload);
      if (response) {
        dispatch(cmsSetState([{ key: "loading", value: false }]));
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };

/**
 * @param {getLocationAPI}
 */
export const getLocationAPI = ({ search, limit, page, projId }) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.get(`/cms/charge_op_location?emailid=${localStorage.getItem("user_id")}&page=${page}&page_size=${limit}&search=${search}&ProjId=${projId}`, null);
      if (response) {
        dispatch(
          cmsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "getLocationData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };


/**
 * @param {deleteLocationAPI}
 */
export const deleteLocationAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.delete(`/cms/charge_op_location/${payload}`, null);
      if (response) {
        dispatch(cmsSetState([{ key: "loading", value: false }]));
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };

/**
 * @param {addSubLocationAPI}
 */
export const addSubLocationAPI = ({ payload, projId }) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post(`/cms/charger_sub_loc?projId=${projId}`, payload);
      if (response) {
        dispatch(
          cmsSetState([{ key: "loading", value: false }])
        );
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };

/**
* @param {putSubLocationAPI}
*/
export const putSubLocationAPI = ({ id, payload }) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.put(`/cms/charger_sub_loc/${id}/`, payload);
      if (response) {
        dispatch(cmsSetState([{ key: "loading", value: false }]));
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };

/**
 * @param {deleteSubLocationAPI}
 */
export const deleteSubLocationAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.delete(`/cms/charger_sub_loc/${payload}`, null);
      if (response) {
        dispatch(cmsSetState([{ key: "loading", value: false }]));
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };

/**
 * @param {getSubLocationAPI}
 */
export const getSubLocationAPI = ({ search, limit, page, projId }) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.get(`/cms/charger_sub_loc?emailid=${localStorage.getItem("user_id")}&page=${page}&page_size=${limit}&search=${search}&ProjId=${projId}`, null);
      if (response) {
        dispatch(
          cmsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "getSubLocationData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };

/**
 * @param {addFacilityAPI}
 */
export const addFacilityAPI = ({ payload, projId }) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post(`/cms/facility?projId=${projId}`, payload);
      if (response) {
        dispatch(cmsSetState([{ key: "loading", value: false }]));
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };

/**
* @param {putFacilityAPI}
*/
export const putFacilityAPI = ({ id, payload }) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.put(`/cms/facility/${id}/`, payload);
      if (response) {
        dispatch(cmsSetState([{ key: "loading", value: false }]));
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };

/**
 * @param {deleteFacilityAPI}
 */
export const deleteFacilityAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.delete(`/cms/facility/${payload}`, null);
      if (response) {
        dispatch(cmsSetState([{ key: "loading", value: false }]));
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };

/**
 * @param {getFacilityAPI}
 */
export const getFacilityAPI = ({ search, limit, page, projId }) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.get(`/cms/facility?emailid=${localStorage.getItem("user_id")}&page=${page}&page_size=${limit}&search=${search}&ProjId=${projId}`, null);
      if (response) {
        dispatch(
          cmsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "getFacilityData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };

/**
 * @param {addOperatorAPI}
 */
export const addOperatorAPI = ({ payload, projId }) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post(`/cms/operator?projId=${projId}`, payload);
      if (response) {
        dispatch(cmsSetState([{ key: "loading", value: false }]));
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };

/**
* @param {putOperatorAPI}
*/
export const putOperatorAPI = ({ id, payload }) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.put(`/cms/operator/${id}/`, payload);
      if (response) {
        dispatch(cmsSetState([{ key: "loading", value: false }]));
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };

/**
* @param {deleteOperatorAPI}
*/
export const deleteOperatorAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.delete(`/cms/operator/${payload}`, null);
      if (response) {
        dispatch(cmsSetState([{ key: "loading", value: false }]));
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };

/**
* @param {getOperatorAPI}
*/
export const getOperatorAPI = ({ search, limit, page, projId }) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.get(`/cms/operator?emailid=${localStorage.getItem("user_id")}&page=${page}&page_size=${limit}&search=${search}&ProjId=${projId}`, null);
      if (response) {
        dispatch(
          cmsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "getOperatorData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };

/**
 * @param {addSubOperatorAPI}
 */
export const addSubOperatorAPI = ({ payload, projId }) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post(`/cms/sub_operator?projId=${projId}`, payload);
      if (response) {
        dispatch(cmsSetState([{ key: "loading", value: false }]));
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };

/**
* @param {putSubOperatorAPI}
*/
export const putSubOperatorAPI = ({ id, payload }) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.put(`/cms/sub_operator/${id}/`, payload);
      if (response) {
        dispatch(cmsSetState([{ key: "loading", value: false }]));
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };

/**
* @param {deleteSubOperatorAPI}
*/
export const deleteSubOperatorAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.delete(`/cms/sub_operator/${payload}`, null);
      if (response) {
        dispatch(cmsSetState([{ key: "loading", value: false }]));
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };

/**
* @param {getSubOperatorAPI}
*/
export const getSubOperatorAPI = ({ search, limit, page, projId }) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.get(`/cms/sub_operator?emailid=${localStorage.getItem("user_id")}&page=${page}&page_size=${limit}&search=${search}&ProjId=${projId}`, null);
      if (response) {
        dispatch(
          cmsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "getSubOperatorData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };

/**
 * @param {addCityAPI}
 */
export const addCityAPI = ({ payload, projId }) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post(`/cms/city?projId=${projId}`, payload);
      if (response) {
        dispatch(cmsSetState([{ key: "loading", value: false }]));
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };

/**
* @param {putCityAPI}
*/
export const putCityAPI = ({ id, payload }) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.put(`/cms/city/${id}/`, payload);
      if (response) {
        dispatch(cmsSetState([{ key: "loading", value: false }]));
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };

/**
* @param {deleteCityAPI}
*/
export const deleteCityAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.delete(`/cms/city/${payload}`, null);
      if (response) {
        dispatch(cmsSetState([{ key: "loading", value: false }]));
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };

/**
* @param {getCityAPI}
*/
export const getCityAPI = ({ search, limit, page, projId }) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.get(`/cms/city?emailid=${localStorage.getItem("user_id")}&page=${page}&page_size=${limit}&search=${search}&ProjId=${projId}`, null);
      if (response) {
        dispatch(
          cmsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "getCityData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };

/**
* @param {addOpeningHoursAPI}
*/
export const addOpeningHoursAPI = ({ payload, projId }) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post(`/cms/opening_hours?projId=${projId}`, payload);
      if (response) {
        dispatch(cmsSetState([{ key: "loading", value: false }]));
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };

/**
* @param {putOpeningHoursAPI}
*/
export const putOpeningHoursAPI = ({ id, payload }) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.put(`/cms/opening_hours/${id}/`, payload);
      if (response) {
        dispatch(cmsSetState([{ key: "loading", value: false }]));
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };

/**
* @param {deleteOpeningHoursAPI}
*/
export const deleteOpeningHoursAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.delete(`/cms/opening_hours/${payload}`, null);
      if (response) {
        dispatch(cmsSetState([{ key: "loading", value: false }]));
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };

/**
* @param {getOpeningHoursAPI}
*/
export const getOpeningHoursAPI = ({ search, limit, page, projId }) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.get(`/cms/opening_hours?emailid=${localStorage.getItem("user_id")}&page=${page}&page_size=${limit}&search=${search}&ProjId=${projId}`, null);
      if (response) {
        dispatch(
          cmsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "getOpeningHoursData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };


/**
* @param {addRFIDCardAPI}
*/
export const addRFIDCardAPI = ({ payload, projId }) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post(`/cms/rfidcard?projId=${projId}`, payload);
      if (response) {
        dispatch(cmsSetState([{ key: "loading", value: false }]));
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };

/**
* @param {putRFIDCardAPI}
*/
export const putRFIDCardAPI = ({ projId, id, payload }) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.put(`/cms/rfidcard/${id}/?projId=${projId}`, payload);
      if (response) {
        dispatch(cmsSetState([{ key: "loading", value: false }]));
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };

/**
* @param {deleteRFIDCardAPI}
*/
export const deleteRFIDCardAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.delete(`/cms/rfidcard/${payload}`, null);
      if (response) {
        dispatch(cmsSetState([{ key: "loading", value: false }]));
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };

/**
* @param {getRFIDCardAPI}
*/
export const getRFIDCardAPI = ({ search, limit, page, projId, start }) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.get(`/cms/rfidcard?emailid=${localStorage.getItem("user_id")}&page=${page}&page_size=${limit}&search=${search}&ProjId=${projId}${start ? `&start=yes` : ''}`, null);
      if (response) {
        dispatch(
          cmsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "getRFIDCardData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };

/**
* @param {getActiveLogsAPI}
*/
export const getActiveLogsAPI = ({ projId, rfid }) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.get(`/cms/all_sessions?project_id=${projId}&rfid=${rfid}`, null);
      if (response) {
        dispatch(
          cmsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };

/**
* @param {addEVSETypeAPI}
*/
export const addEVSETypeAPI = ({ payload, projId }) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post(`/cms/esve_type?projId=${projId}`, payload);
      if (response) {
        dispatch(cmsSetState([{ key: "loading", value: false }]));
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };

/**
* @param {putEVSETypeAPI}
*/
export const putEVSETypeAPI = ({ projId, id, payload }) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.put(`/cms/esve_type/${id}/?projId=${projId}`, payload);
      if (response) {
        dispatch(cmsSetState([{ key: "loading", value: false }]));
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };

/**
* @param {deleteEVSETypeAPI}
*/
export const deleteEVSETypeAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.delete(`/cms/esve_type/${payload}`, null);
      if (response) {
        dispatch(cmsSetState([{ key: "loading", value: false }]));
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };

/**
* @param {getEVSETypeAPI}
*/
export const getEVSETypeAPI = ({ search, limit, page, projId }) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.get(`/cms/esve_type?emailid=${localStorage.getItem("user_id")}&page=${page}&page_size=${limit}&search=${search}&ProjId=${projId}`, null);
      if (response) {
        dispatch(
          cmsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "getEVSETypeData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };


/**
* @param {addStationAPI}
*/
export const addStationAPI = ({ payload, projId }) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post(`/cms/charger_point?projId=${projId}`, payload);
      if (response) {
        dispatch(cmsSetState([{ key: "loading", value: false }]));
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };

/**
* @param {putStationAPI}
*/
export const putStationAPI = ({ projId, id, payload }) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.put(`/cms/charger_point/${id}/?projId=${projId}`, payload);
      if (response) {
        dispatch(cmsSetState([{ key: "loading", value: false }]));
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };

/**
* @param {deleteStationAPI}
*/
export const deleteStationAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.delete(`/cms/charger_point/${payload}`, null);
      if (response) {
        dispatch(cmsSetState([{ key: "loading", value: false }]));
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };

/**
* @param {getStationAPI}
*/
export const getStationAPI = ({ search, limit, page, projId }) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.get(`/cms/charger_point?emailid=${localStorage.getItem("user_id")}&page=${page}&page_size=${limit}&search=${search}&ProjId=${projId}`, null);
      if (response) {
        dispatch(
          cmsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "getStationData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };

/**
* @param {getStationDetailsAPI}
*/
export const getStationDetailsAPI = ({ ChargerId }) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.get(`/cms/charger_all_pages?user_email=${localStorage.getItem("user_id")}${ChargerId ? `&ChargerId=${ChargerId}` : ''}`, null);
      if (response) {
        dispatch(
          cmsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "getStationDetailsData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;

    }
  };

/**
* @param {getChargerGroupCapacityAPI}
*/
export const getChargerGroupCapacityAPI = (data) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.get(`/cms/charger_group_capacity?sublocation_id=${data}`, null);
      if (response) {
        dispatch(
          cmsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "getChargerGroupCapacityData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");
      return error?.response?.data;

    }
  };

/**
* @param {getSessionLogsAPI}
*/
export const getSessionLogsAPI = ({ data }) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.get(`/cms/all_sessions${data}`, null);
      if (response) {
        dispatch(
          cmsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "getSessionLogsData", value: response?.data?.content?.[0]?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");
      return error?.response?.data;

    }
  };

/**
* @param {getConfigurationAPI}
*/
export const getConfigurationAPI = (data) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post(`/cms/get_configuration`, data);
      if (response) {
        dispatch(
          cmsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "getConfigurationData", value: response?.data?.content?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");
      return error?.response?.data;
    }
  };

/**
* @param {getDiagnosticsAPI}
*/
export const getDiagnosticsAPI = (data) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.get(`/cms/individual_logs${data}`, null);
      if (response) {
        dispatch(
          cmsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "getDiagnosticsData", value: response?.data?.content?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");
      return error?.response?.data;
    }
  };

/**
* @param {changeConfigurationAPI}
*/
export const changeConfigurationAPI = (data) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post(`/cms/change_configuration`, data);
      if (response) {
        dispatch(
          cmsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");
      return error?.response?.data;
    }
  };

/**
* @param {triggerMessageAPI}
*/
export const triggerMessageAPI = (data) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post(`/cms/trigger_message`, data);
      if (response) {
        dispatch(
          cmsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "triggerMessageData", value: response?.data?.content?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;
    }
  };

/**
* @param {allSessionsAPI}
*/
export const allSessionsAPI = ({ data }) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.get(`/cms/all_sessions${data}`, null);
      if (response) {
        dispatch(
          cmsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "allSessionsData", value: response?.data?.content },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");
      return error?.response?.data;
    }
  };

/**
* @param {changeAvailabilityAPI}
*/
export const changeAvailabilityAPI = (data) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post(`/cms/change_availability`, data);
      if (response) {
        dispatch(
          cmsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;
    }
  };

/**
* @param {remoteStartTransactionAPI}
*/
export const remoteStartTransactionAPI = (data) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post(`/cms/remote_start_transaction`, data);
      if (response) {
        dispatch(
          cmsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;
    }
  };

/**
* @param {remoteStopTransactionAPI}
*/
export const remoteStopTransactionAPI = (data) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post(`/cms/remote_stop_transaction`, data);
      if (response) {
        dispatch(
          cmsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;
    }
  };

/**
* @param {overviewAllAPI}
*/
export const overviewAllAPI = (data) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.get(`/cms/overview_all${data}`, null);
      if (response) {
        dispatch(
          cmsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "overviewAllData", value: response?.data?.charger_points },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;
    }
  };

/**
* @param {locationIndividualAPI}
*/
export const locationIndividualAPI = (data) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.get(`/cms/location_individual${data}`, null);
      if (response) {
        dispatch(
          cmsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "locationIndividualData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;
    }
  };

/**
* @param {overviewAPI}
*/
export const overviewAPI = (data) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.get(`/cms/overview${data}`, null);
      if (response) {
        dispatch(
          cmsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "overviewData", value: response?.data?.charger_points },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;
    }
  };

/**
* @param {overview_perAPI}
*/
export const overview_perAPI = (data) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.get(`/cms/overview_per${data}`, null);
      if (response) {
        dispatch(
          cmsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "overviewPerData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");

      return error?.response?.data;
    }
  };

/**
* @param {avgSessionLengthAPI}
*/
export const avgSessionLengthAPI = (data) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.get(`/cms/avg_session_length${data}`, null);
      if (response) {
        dispatch(
          cmsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "avgSessionLengthData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");
      return error?.response?.data;
    }
  };

/**
* @param {overviewGraphAPI}
*/
export const overviewGraphAPI = (data) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.get(`/cms/overview_graph${data}`, null);
      if (response) {
        dispatch(
          cmsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "overviewGraphData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");
      return error?.response?.data;
    }
  };

/**
* @param {availablityStatusAPI}
*/
export const availablityStatusAPI = (data) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.get(`/cms/availablity_status${data}`, null);
      if (response) {
        dispatch(
          cmsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "availablityStatusData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");
      return error?.response?.data;
    }
  };

/**
* @param {websocketOverviewAPI}
*/
export const websocketOverviewAPI = (data) =>
  async (dispatch) => {
    try {
      dispatch(cmsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.get(`/cms/websocket_overview${data}`, null);
      if (response) {
        dispatch(
          cmsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "websocketOverviewData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(cmsSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.error || "Error from api side");
      return error?.response?.data;
    }
  };

// ** Create Slice
/**
 * @param {cmsSlice}
 */
const cmsSlice = createSlice({
  name: "cms",
  initialState: initialStates,
  reducers: {
    cmsSetState: (state, { payload }) => {
      if (Array.isArray(payload)) {
        for (const obj of payload) {
          _.set(state, obj.key, obj.value);
        }
      } else {
        _.set(state, payload.key, payload.value);
      }
    },
  },
});

export const { cmsSetState } = cmsSlice.actions;

const { reducer } = cmsSlice;

export default reducer;
