
import "../assets/scss/custom.scss";
import logo from "../../src/assets/images/logo/dura-power-logo.svg";

//Template config options
const themeConfig = {
  app: {
    appName: <img src={logo} className="side-logo" alt="side-logo" />,
    appLogoImage: <img src={logo} alt="logo" />,
  },
  layout: {
    isRTL: false,
    skin: "dark",
    routerTransition: "fadeIn",
    type: "vertical",
    contentWidth: "boxed",
    menu: {
      isHidden: false,
      isCollapsed: true,
    },
    navbar: {
      type: "floating",
      backgroundColor: "white",
    },
    footer: {
      type: "static",
    },
    customizer: false,
    scrollTop: true,
  },
};

export default themeConfig;
