import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { axiosApi } from "../../helpers/axios";
import { toast } from "react-toastify";

const initialStates = {
  error: {},
  loading: false,
  loginData: null,
  registerData: null,
  userPermissions: null,
  inquiryData: null,
};

/**
 * @param {InquiryAPI}
 */
export const InquiryAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(authSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post("https://dppulse.com/sendmail", payload);
      if (response) {
        dispatch(
          authSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "inquiryData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(authSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.detail || "Error from backend side.");
      return error?.response?.data;
    }
  };

/**
 * @param {LoginAPI}
 */
export const LoginAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(authSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post("/api/auth/login/", payload);
      if (response) {
        dispatch(
          authSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "loginData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(authSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.detail || "Error from backend side.");
      return error?.response?.data;
    }
  };

/**
 * @param {RegisterAPI}
 */
export const RegisterAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(authSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post("/api/auth/register/", payload);
      if (response) {
        dispatch(
          authSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "registerData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(authSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.detail || "Error from backend side.");
      return error?.response?.data;
    }
  };

/**
 * @param {getUserInfoAPI}
 */
export const getUserInfoAPI = (data) =>
  async (dispatch) => {
    try {
      dispatch(authSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post("/batteries/user_restrict", data);
      if (response) {
        dispatch(
          authSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "userPermissions", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(authSetState([{ key: "loading", value: false }]));
      toast.error(error?.response?.data?.detail || "Error from backend side.");
      return error?.response?.data;
    }
  };




// ** Create Slice
/**
 * @param {authSlice}
 */
const authSlice = createSlice({
  name: "auth",
  initialState: initialStates,
  reducers: {
    authSetState: (state, { payload }) => {
      if (Array.isArray(payload)) {
        for (const obj of payload) {
          _.set(state, obj.key, obj.value);
        }
      } else {
        _.set(state, payload.key, payload.value);
      }
    },
  },
});

export const { authSetState } = authSlice.actions;

const { reducer } = authSlice;

export default reducer;
