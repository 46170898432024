// ** Logo
import { DuraPowerLogo } from "../../../assets/images";

const SpinnerComponent = () => {
  return (
    <div
      className="fallback-spinner app-loader d-flex align-items-center justify-content-center"
      style={{ height: "100vh" }}
    >
      <img
        className="fallback-logo"
        src={DuraPowerLogo}
        alt="logo"
        style={{ width: "140px" }}
      />
      <div className="loading">
        <div className="effect-1 effects"></div>
        <div className="effect-2 effects"></div>
        <div className="effect-3 effects"></div>
      </div>
    </div>
  );
};

export default SpinnerComponent;
