import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { axiosApi } from "../../helpers/axios";
import { toast } from "react-toastify";

const initialStates = {
  error: {},
  loading: false,
  analySOCKmData: null,
  analyChargeKmData: null,
  analyDischargeKmData: null,
  analySpeedData: null,
  analyEnergyData: null,
  analyLoadData: null,
  unscheduledMainData: null,
  operationalMainData: null,
  deleteMaintenanceData: null,
  getMaintenanceData: null,
  postMaintenanceData: null,
  putMaintenanceData: null,
  highEventData: null,
  SafetyTableData: null,
};

/**
 * @param {AnalyLoadAPI}
 */
export const AnalyLoadAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(analyticsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post("/batteries/loadanalysis", payload);
      if (response) {
        dispatch(
          analyticsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "analyLoadData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(analyticsSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {AnalyEnergyAPI}
 */
export const AnalyEnergyAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(analyticsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post("/batteries/energy_efficency_analytics", payload);
      if (response) {
        dispatch(
          analyticsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "analyEnergyData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(analyticsSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {AnalySpeedAPI}
 */
export const AnalySpeedAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(analyticsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post("/batteries/speed_analysis", payload);
      if (response) {
        dispatch(
          analyticsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "analySpeedData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(analyticsSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {AnalyDischargeKmAPI}
 */
export const AnalyDischargeKmAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(analyticsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post("/batteries/discharge_per_deltav", payload);
      if (response) {
        dispatch(
          analyticsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "analyDischargeKmData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(analyticsSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {HighEventAPI}
 */
export const HighEventAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(analyticsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post("/batteries/high_event", payload);
      if (response) {
        dispatch(
          analyticsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "highEventData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(analyticsSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {SafetyTableAPI}
 */
export const SafetyTableAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(analyticsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post("/batteries/safety", payload);
      if (response) {
        dispatch(
          analyticsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "SafetyTableData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(analyticsSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {AnalyChargeKmAPI}
 */
export const AnalyChargeKmAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(analyticsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post("/batteries/cost_soc_km_analytics", payload);
      if (response) {
        dispatch(
          analyticsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "analyChargeKmData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(analyticsSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {AnalySOCKmAPI}
 */
export const AnalySOCKmAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(analyticsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post("/batteries/analy_soc_km", payload);
      if (response) {
        dispatch(
          analyticsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "analySOCKmData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(analyticsSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {UnscheduledMainAPI}
 */
export const UnscheduledMainAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(analyticsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post("/batteries/unscheduled_maintenance", payload);
      if (response) {
        dispatch(
          analyticsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "unscheduledMainData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(analyticsSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {OperationalMainAPI}
 */
export const OperationalMainAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(analyticsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post("/batteries/operational_maintenance", payload);
      if (response) {
        dispatch(
          analyticsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "operationalMainData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(analyticsSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {getMaintenanceAPI}
 */
export const getMaintenanceAPI = ({ search, limit, page, projId }) =>
  async (dispatch) => {
    try {
      dispatch(analyticsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.get(`/batteries/maintenance_operations?emailid=${localStorage.getItem("user_id")}&page=${page}&page_size=${limit}&search=${search}&ProjId=${projId}`, null);
      if (response) {
        dispatch(
          analyticsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "getMaintenanceData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(analyticsSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {postMaintenanceAPI}
 */
export const postMaintenanceAPI = ({ projId, payload }) =>
  async (dispatch) => {
    try {
      dispatch(analyticsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post(`/batteries/maintenance_operations?emailid=${localStorage.getItem("user_id")}&ProjId=${projId}`, payload);
      if (response) {
        dispatch(
          analyticsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "postMaintenanceData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(analyticsSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {putMaintenanceAPI}
 */
export const putMaintenanceAPI = ({ id, payload, projId }) =>
  async (dispatch) => {
    try {
      dispatch(analyticsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.put(`/batteries/maintenance_operations/${id}/?emailid=${localStorage.getItem("user_id")}&ProjId=${projId}`, payload);
      if (response) {
        dispatch(
          analyticsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "putMaintenanceData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(analyticsSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {deleteMaintenanceAPI}
 */
export const deleteMaintenanceAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(analyticsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.delete("/batteries/maintenance_operations/" + payload, null);
      if (response) {
        dispatch(
          analyticsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "deleteMaintenanceData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(analyticsSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };




// ** Create Slice
/**
 * @param {analyticsSlice}
 */
const analyticsSlice = createSlice({
  name: "analytics",
  initialState: initialStates,
  reducers: {
    analyticsSetState: (state, { payload }) => {
      if (Array.isArray(payload)) {
        for (const obj of payload) {
          _.set(state, obj.key, obj.value);
        }
      } else {
        _.set(state, payload.key, payload.value);
      }
    },
  },
});

export const { analyticsSetState } = analyticsSlice.actions;

const { reducer } = analyticsSlice;

export default reducer;
