// ** Redux Imports
import layout from "./layout";
import navbar from "./navbar";

import { applyMiddleware, configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import dashboardReducer from "./dashboard/slice";
import authdReducer from "./auth/slice";
import batterydReducer from "./battery/slice";
import emsReducer from "./ems/slice";
import cmsReducer from "./cms/slice";
import analyticsReducer from "./analytics/slice";
import thunk from "redux-thunk";


const rootReducer = {
  navbar,
  layout,
  dashboard: dashboardReducer,
  auth: authdReducer,
  battery: batterydReducer,
  ems: emsReducer,
  cms: cmsReducer,
  analytics: analyticsReducer,
};

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false,
});

export default configureStore({
  reducer: rootReducer,
  middleware: customizedMiddleware,
}, applyMiddleware(thunk));
