import InstagramLogo from "./logo/instagram.png";
import TwitterLogo from "./logo/twitter.png";
import FacebookLogo from "./logo/facebook.png";
import TikTokLogo from "./logo/Group 12048.png";
import NoDataLogo from "./logo/no-data.png";
import userDummy from "./logo/person.png";
import DuraPowerLogo from "./landing/logo.png";
import DuraPowerLogoLight from "./landing/logo.png";
import Battery from "./logo/battery.svg";
import Car from "./logo/car.svg";
import Home from "./logo/home.svg";
import User from "./logo/user.svg";
import BGBattery from "./logo/bg-battery.svg";
import BGDiamond from "./logo/bg-diamong.svg";
import BGVarification from "./logo/bg-varification.svg";
import Notification from "./logo/notification.svg";
import BGCharge from "./logo/bg-charge.svg";
import BGDolar from "./logo/bg-dolar.svg";
import RoundBlue from "./logo/round-blue.svg";
import RoundPink from "./logo/round-pink.svg";
import RoundGreen from "./logo/round-green.svg";
import RoundGrey from "./logo/round-grey.svg";
import Calender from "./logo/calender.svg";
import BatteryWorking from "./logo/battery-working.svg";
import TempCharge from "./logo/temp-charge.svg";
import Polygon from "./logo/polygon.svg";
import Shield from "./logo/shield.svg";
import BatteryEmpty from "./logo/battery-empty.svg";
import BatteryTips from "./logo/battery-tips.svg";
import Asterik from "./logo/fi-rr-asterik.svg";
import Chart from "./logo/chart.svg";
import Analytics from "./logo/analytics.svg";
import OVERVIEW_WIND from "./logo/wind.svg";
import OVERVIEW_BATTERY from "./logo/battery-main.svg";
import OVERVIEW_CONTROLLER from "./logo/controller.svg";
import OVERVIEW_PV from "./logo/solar-panel.svg";
import OVERVIEW_LOAD from "./logo/load.svg";
import OVERVIEW_GENETOR from "./logo/generator.svg";
import LEFT_ARROW from "./logo/left-arrow.png";
import RIGHT_ARROW from "./logo/right-arrow.png";
import UP_ARROW from "./logo/up-arrow.png";
import DOWN_ARROW from "./logo/down-arrow.png";
import Check from "./logo/fi-rr-list-check.svg";
import Plug from "./logo/plug.svg";
import ForWard from "./logo/forword.svg";
import BackWard from "./logo/backward.svg";
import HeadBottomShadow from "./logo/head_bottom_shadow.svg";
import BGHeaderButton from "./logo/bg_header_button.svg";
import MapTopShape from "./logo/map_top_shape.svg";
import MapBackWard from "./logo/map_back_ward.svg";
import MapForWard from "./logo/map_for_ward.svg";
import NO_DATA from "./logo/no-data.svg";
import CLOSE from "./logo/fi-rr-cross-small.svg";
import SELECT_TIME from "./logo/select_time.svg";
import INFO_ICON from "./logo/info.png";
import LANDING_PAGE_BG from "./landing/landing_page.png";
import LANDING_PAGE_BG_SVG from "./landing/landing_page_svg.svg";
import Round from "./landing/round2.png";
import LANDING_LOGO from "./landing/logo.png";
import LANDING_1 from "./landing/landing_1.png";
import LANDING_2 from "./landing/landing_2.png";
import LANDING_3 from "./landing/landing_3.png";
import LANDING_4 from "./landing/landing_4.png";
import LANDING_LIFE_CYCLE from "./landing/landing_lifecycle.png";
import LANDING_PASSPORT from "./landing/landing_passport.png";
import LANDING_PERFORMANCE from "./landing/landing_per.png";
import EXPLORE_MORE from "./landing/explore_more.png";
import FEATURE_BG from "./landing/feature_bg.svg";
import FEATURE_BG_END from "./landing/feature_bg_end.svg";
import FEATURE_BG_START from "./landing/feature_bg_start.svg";
import FEATURE_BG_ROUND from "./landing/feature_round_bg.svg";
import FEATURE_FORM_BG from "./landing/form_bg.svg";
import ARROW_UP_RIGHT from "./landing/arrow-up-right.svg";
import BTN_FEATURES from "./cms/btn-features.svg";
import BTN_GET_STARTED from "./cms/btn-get-started.svg";
import BTN_SEND_ENQUIRY from "./cms/btn-send-enquiry.svg";
import LEFT1 from "./landing/left1.svg";
import LEFT2 from "./landing/left2.svg";
import LEFT3 from "./landing/left3.svg";
import RIGHT1 from "./landing/right1.svg";
import RIGHT2 from "./landing/right2.svg";
import RIGHT3 from "./landing/right3.svg";
import CROSSLINE from "./landing/line2.png";
import ROUND_CENTER from "./landing/round3.png";
import LOGIN_BTN from "./landing/login_btn.png";
import DP_FEATURE_BTN from "./landing/dp_feature_btn2.svg";
import RIGHT_DOUBLE_SMALL from "./landing/fi-rr-angle-double-small-right.svg";
import MEN_IAMGE from "./landing/man_image.png";
import MEN_IMAGE_2 from "./landing/man_image_2.png";
import WOMEN_IMAGE from "./landing/women_image.png";
import COMMA from "./landing/comma.png";

export {
  COMMA,
  WOMEN_IMAGE,
  MEN_IAMGE,
  MEN_IMAGE_2,
  RIGHT_DOUBLE_SMALL,
  RoundPink,
  ROUND_CENTER,
  CROSSLINE,
  LEFT1,
  LEFT2,
  LEFT3,
  RIGHT1,
  RIGHT2,
  RIGHT3,
  DP_FEATURE_BTN,
  LOGIN_BTN,
  BTN_FEATURES,
  BTN_GET_STARTED,
  BTN_SEND_ENQUIRY,
  ARROW_UP_RIGHT,
  FEATURE_FORM_BG,
  FEATURE_BG,
  FEATURE_BG_END,
  FEATURE_BG_START,
  FEATURE_BG_ROUND,
  LANDING_1,
  LANDING_2,
  LANDING_3,
  LANDING_4,
  LANDING_LIFE_CYCLE,
  LANDING_PASSPORT,
  LANDING_PERFORMANCE,
  LANDING_LOGO,
  EXPLORE_MORE,
  Round,
  LANDING_PAGE_BG_SVG,
  INFO_ICON,
  SELECT_TIME,
  CLOSE,
  NO_DATA,
  MapBackWard,
  MapForWard,
  MapTopShape,
  BGHeaderButton,
  HeadBottomShadow,
  BackWard,
  ForWard,
  Plug,
  Check,
  LEFT_ARROW,
  RIGHT_ARROW,
  UP_ARROW,
  DOWN_ARROW,
  OVERVIEW_WIND,
  OVERVIEW_BATTERY,
  OVERVIEW_CONTROLLER,
  OVERVIEW_PV,
  OVERVIEW_LOAD,
  OVERVIEW_GENETOR,
  DuraPowerLogoLight,
  Chart,
  Analytics,
  Asterik,
  Polygon,
  Shield,
  BatteryEmpty,
  BatteryTips,
  BatteryWorking,
  TempCharge,
  RoundBlue,
  RoundGreen,
  RoundGrey,
  BGCharge,
  BGDolar,
  BGBattery,
  BGDiamond,
  BGVarification,
  Notification,
  Battery,
  Car,
  Home,
  User,
  InstagramLogo,
  TwitterLogo,
  TikTokLogo,
  FacebookLogo,
  NoDataLogo,
  userDummy,
  DuraPowerLogo,
  Calender,
};
