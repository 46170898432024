import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { axiosApi } from "../../helpers/axios";

const initialStates = {
  error: {},
  loading: false,
  dashboardChartsData: null,
  dateRangeData: null,
  project_id: null,
  project_name: null,
  coordsData: null,
  cardOverviewData: null,
  batteryTableData: null,
  allBatteryData: null,
  dateType: "",
  vehicleListData: [],
  vehicleNameData: "",
  batteryDetailsData: null,
  setDashboardBatteryRefData: null,
  setDashboardRefData: null,
  locationData: null,
  graphVoltageData: null,
  graphCellVoltageData: null,
  graphCellTempData: null,
  graphTempData: null,
  graphSocData: null,
  graphCurrentData: null,
  systemValuesData: null,
  mileageDetailsData: null,
  mileageChargeData: null,
  alertsOverviewData: null,
  activeAlertsData: null,
  historyAlertsData: null,
  graphDeltaVTrendData: null,
  graphDailyAvgDCAData: null,
  otherAnalyticsData: null,
  alertsAnalyticsData: null,
};

/**
 * @param {dashboardChartsAPI}
 */
export const dashboardChartsAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(dashboardSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post("/batteries/charts", payload);
      if (response) {
        dispatch(
          dashboardSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "dashboardChartsData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(dashboardSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {systemValuesAPI}
 */
export const systemValuesAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(dashboardSetState([{ key: "loading", value: false }]));
      const response = await axiosApi.post("/batteries/system_values", payload);
      if (response) {
        dispatch(
          dashboardSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "systemValuesData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(dashboardSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {cardOverviewAPI}
 */
export const cardOverviewAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(dashboardSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post("/batteries/card_overview", payload);
      if (response) {
        dispatch(
          dashboardSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "cardOverviewData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(dashboardSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {dashboardDataSetNull}
 */
export const dashboardDataSetNull = (payload) =>
  async (dispatch) => {
    dispatch(
      dashboardSetState([
        { key: "cardOverviewData", value: null },
        { key: "dashboardChartsData", value: null },
      ])
    );
  };

/**
 * @param {batteryTableAPI}
 */
export const batteryTableAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(dashboardSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post("/batteries/table_content", payload);
      if (response) {
        dispatch(
          dashboardSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "batteryTableData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(dashboardSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {allBatteryAPI}
 */
export const allBatteryAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(dashboardSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post("/batteries/proj_details", payload);
      if (response) {
        dispatch(
          dashboardSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "allBatteryData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(dashboardSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {avgOverAPI}
 */
export const avgOverAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(dashboardSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post("/batteries/avg_over", payload);
      if (response) {
        dispatch(
          dashboardSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "avgOverData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(dashboardSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {coordsAPI}
 */
export const coordsAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(dashboardSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post("/batteries/coords", payload);
      if (response) {
        dispatch(
          dashboardSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "coordsData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(dashboardSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {vehicleListAPI}
 */
export const vehicleListAPI = (payload) =>
  async (dispatch) => {
    try {
      const response = await axiosApi.post("/batteries/vehicle_list", payload);
      if (response) {
        dispatch(
          dashboardSetState([
            { key: "error", value: {} },
            { key: "vehicleListData", value: response?.data?.vehiclelist },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(dashboardSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {batteryDetailsAPI}
 */
export const batteryDetailsAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(dashboardSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post("/batteries/battery_datasphere", payload);
      if (response) {
        dispatch(
          dashboardSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "batteryDetailsData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(dashboardSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {alertsOverviewAPI}
 */
export const alertsOverviewAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(dashboardSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post("/batteries/alerts_overview", payload);
      if (response) {
        dispatch(
          dashboardSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "alertsOverviewData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(dashboardSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {historyAlertsAPI}
 */
export const historyAlertsAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(dashboardSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post("/batteries/history_alert_datas", payload);
      if (response) {
        dispatch(
          dashboardSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "historyAlertsData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(dashboardSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {activeAlertsAPI}
 */
export const activeAlertsAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(dashboardSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post("/batteries/active_alert_datas", payload);
      if (response) {
        dispatch(
          dashboardSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "activeAlertsData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(dashboardSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {mileageDetailsAPI}
 */
export const mileageDetailsAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(dashboardSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post("/batteries/mileage_ind", payload);
      if (response) {
        dispatch(
          dashboardSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "mileageDetailsData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(dashboardSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {mileageChargeAPI}
 */
export const mileageChargeAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(dashboardSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post("/batteries/milage_charge", payload);
      if (response) {
        dispatch(
          dashboardSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "mileageChargeData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(dashboardSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {locationAPI}
 */
export const locationAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(dashboardSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post("/batteries/location", payload);
      if (response) {
        dispatch(
          dashboardSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "locationData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(dashboardSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {graphVoltageAPI}
 */
export const graphVoltageAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(dashboardSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post("/batteries/voltage", payload);
      if (response) {
        dispatch(
          dashboardSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "graphVoltageData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(dashboardSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {graphCellVoltageAPI}
 */
export const graphCellVoltageAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(dashboardSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post("/batteries/cell_voltage", payload);
      if (response) {
        dispatch(
          dashboardSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "graphCellVoltageData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(dashboardSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {graphCellTempAPI}
 */
export const graphCellTempAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(dashboardSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post("/batteries/cell_temp", payload);
      if (response) {
        dispatch(
          dashboardSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "graphCellTempData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(dashboardSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {graphTempAPI}
 */
export const graphTempAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(dashboardSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post("/batteries/temperature", payload);
      if (response) {
        dispatch(
          dashboardSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "graphTempData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(dashboardSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {graphSocAPI}
 */
export const graphSocAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(dashboardSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post("/batteries/soc", payload);
      if (response) {
        dispatch(
          dashboardSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "graphSocData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(dashboardSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {graphCurrentAPI}
 */
export const graphCurrentAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(dashboardSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post("/batteries/current", payload);
      if (response) {
        dispatch(
          dashboardSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "graphCurrentData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(dashboardSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {graphDeltaVTrendAPI}
 */
export const graphDeltaVTrendAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(dashboardSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post("/batteries/deltav_trend", payload);
      if (response) {
        dispatch(
          dashboardSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "graphDeltaVTrendData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(dashboardSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {graphDailyAvgDCAAPI}
 */
export const graphDailyAvgDCAAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(dashboardSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post("/batteries/daily_avg_dca", payload);
      if (response) {
        dispatch(
          dashboardSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "graphDailyAvgDCAData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(dashboardSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {otherAnalyticsAPI}
 */
export const otherAnalyticsAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(dashboardSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post("/batteries/other_analytics", payload);
      if (response) {
        dispatch(
          dashboardSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "otherAnalyticsData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(dashboardSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };


/**
 * @param {alertsAnalyticsAPI}
 */
export const alertsAnalyticsAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(dashboardSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post("/batteries/counts_m", payload);
      if (response) {
        dispatch(
          dashboardSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "alertsAnalyticsData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(dashboardSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {removeGraphValue}
 */
export const removeGraphValue = (payload) =>
  async (dispatch) => {
    dispatch(
      dashboardSetState([
        { key: "graphVoltageData", value: null },
        { key: "graphCellVoltageData", value: null },
        { key: "graphCellTempData", value: null },
        { key: "graphTempData", value: null },
        { key: "graphSocData", value: null },
        { key: "graphCurrentData", value: null },
      ])
    );
  };

/**
 * @param {setVehicleName}
 */
export const setVehicleName = (payload) =>
  async (dispatch) => {
    dispatch(dashboardSetState([{ key: "vehicleNameData", value: payload }]));
  };

/**
 * @param {setDateRange}
 */
export const setDateRange = (payload) =>
  async (dispatch) => {
    dispatch(dashboardSetState([{ key: "dateRangeData", value: payload }]));
  };

/**
 * @param {setDateType}
 */
export const setDateType = (payload) =>
  async (dispatch) => {
    dispatch(dashboardSetState([{ key: "dateType", value: payload }]));
  };

/**
 * @param {setProjectID}
 */
export const setProjectID = (payload) =>
  async (dispatch) => {
    dispatch(dashboardSetState([{ key: "project_id", value: payload }]));
  };

/**
 * @param {setProjectName}
 */
export const setProjectName = (payload) =>
  async (dispatch) => {
    dispatch(dashboardSetState([{ key: "project_name", value: payload }]));
  };

/**
 * @param {setDashboardChartData}
 */
export const setDashboardChartData = (payload) =>
  async (dispatch) => {
    dispatch(dashboardSetState([{ key: "dashboardChartsData", value: null }]));
    dispatch(dashboardSetState([{ key: "cardOverviewData", value: null }]));
    dispatch(dashboardSetState([{ key: "batteryTableData", value: null }]));
    dispatch(dashboardSetState([{ key: "coordsData", value: null }]));
  };

/**
 * @param {setBatteryDetailsData}
 */
export const setBatteryDetailsData = (payload) =>
  async (dispatch) => {
    dispatch(dashboardSetState([{ key: "batteryDetailsData", value: null }]));
    dispatch(dashboardSetState([{ key: "otherAnalyticsData", value: null }]));
    dispatch(dashboardSetState([{ key: "graphDeltaVTrendData", value: null }]));
    dispatch(dashboardSetState([{ key: "graphDailyAvgDCAData", value: null }]));
  };

/**
 * @param {setDashboardRef}
 */
export const setDashboardRef = (payload) =>
  async (dispatch) => {
    dispatch(dashboardSetState([{ key: "setDashboardRefData", value: payload }]));
  };

/**
 * @param {setDashboardBatteryRef}
 */
export const setDashboardBatteryRef = (payload) =>
  async (dispatch) => {
    dispatch(dashboardSetState([{ key: "setDashboardBatteryRefData", value: payload }]));
  };


// ** Create Slice
/**
 * @param {dashboardSlice}
 */
const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialStates,
  reducers: {
    dashboardSetState: (state, { payload }) => {
      if (Array.isArray(payload)) {
        for (const obj of payload) {
          _.set(state, obj.key, obj.value);
        }
      } else {
        _.set(state, payload.key, payload.value);
      }
    },
  },
});

export const { dashboardSetState } = dashboardSlice.actions;

const { reducer } = dashboardSlice;

export default reducer;
