import axios from "axios";
import store from '../redux/store';
import { accessTokenAPI, canopyLoginAPI, microgridsAPI } from "../redux/ems/slice";
export const axiosCanopyApi = axios.create({
  baseURL: "https://api.canopypower.com",
});

axiosCanopyApi.defaults.headers.common[
  "Authorization"
] = `Bearer ${localStorage.getItem("canopyAccess")}`;

axiosCanopyApi.interceptors.response.use(
  (response) => response,
  (error) => {
    const statusCode = error?.response?.status;
    if (statusCode === 401) {
      store.dispatch(accessTokenAPI());
      // localStorage.clear();
      // location.reload();

      axios.post("https://api.canopypower.com/login", {
        "email": "jiehong.wong@durapowergroup.com",
        "password": "Durapower123!"
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      }).then((response) => {
        localStorage.setItem("canopyAccess", response?.data?.access_token);
        localStorage.setItem("canopyRefresh", response?.data?.refresh_token);
        axiosCanopyApi.defaults.headers.common["Authorization"] = `Bearer ${response?.data?.access_token}`;
        store.dispatch(microgridsAPI());

      })
    } else if (statusCode === 422) {
      delete axiosCanopyApi.defaults.headers.common[
        "Authorization"
      ];
      axios.post("https://api.canopypower.com/login", {
        "email": "jiehong.wong@durapowergroup.com",
        "password": "Durapower123!"
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      }).then((response) => {
        localStorage.setItem("canopyAccess", response?.data?.access_token);
        localStorage.setItem("canopyRefresh", response?.data?.refresh_token);
        axiosCanopyApi.defaults.headers.common["Authorization"] = `Bearer ${response?.data?.access_token}`;
        store.dispatch(microgridsAPI());

      })
    }
    return Promise.reject(error);
  }
);
