import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { axiosApi } from "../../helpers/axios";

const initialStates = {
  error: {},
  loading: false,
  coordsData: null,
};


/**
 * @param {coordsAPI}
 */
export const coordsAPI = (payload) =>
  async (dispatch) => {
    try {
      dispatch(batterySetState([{ key: "loading", value: true }]));
      const response = await axiosApi.post("/batteries/coords", payload);
      if (response) {
        dispatch(
          batterySetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "coordsData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(batterySetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };


// ** Create Slice
/**
 * @param {batterySlice}
 */
const batterySlice = createSlice({
  name: "battery",
  initialState: initialStates,
  reducers: {
    batterySetState: (state, { payload }) => {
      if (Array.isArray(payload)) {
        for (const obj of payload) {
          _.set(state, obj.key, obj.value);
        }
      } else {
        _.set(state, payload.key, payload.value);
      }
    },
  },
});

export const { batterySetState } = batterySlice.actions;

const { reducer } = batterySlice;

export default reducer;
