import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { axiosCanopyApi } from "../../helpers/axiosCanopy";
import { toast } from "react-toastify";
import { axiosApi } from "../../helpers/axios";

const initialStates = {
  error: {},
  loading: false,
  issuesData: null,
  tasksData: null,
  eventsData: null,
  solarPRChartData: null,
  statusData: null,
  comparisionChartData: null,
  microgridsData: null,
  microgrid_id: null,
  accessTokenData: null,
  systemInfoImageData: null,
  systemInfoMicrogridData: null,
  systemInfoData: null,
  monitoringGraphData: null,
  analyticsOverviewData: null,
  batteryComparisonData: null,
  totalLossesPVData: null,
  performanceRatioData: null,
  prBeforeData: null,
  pVPeakData: null,
  pVLossesDetailsData: null,
  batteryPerformanceData: null,
  batteryWarrantyGraphData: null,
  batteryWarrantyInfoData: null,
};

/**
 * @param {issuesAPI}
 */
export const issuesAPI = ({ id }) =>
  async (dispatch) => {
    try {
      dispatch(emsSetState([{ key: "loading", value: true }]));
      const response = await axiosCanopyApi.get(`/microgrids/${id}/issues`, null);
      if (response) {
        dispatch(
          emsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "issuesData", value: response?.data?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(emsSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {accessTokenAPI}
 */
export const accessTokenAPI = () =>
  async (dispatch) => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem("canopyRefresh")}`
    }
    try {
      dispatch(emsSetState([{ key: "loading", value: true }]));
      const response = await axiosCanopyApi.post(`/refresh-token`, null, {
        headers: headers
      });
      if (response) {

        dispatch(
          emsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "accessTokenData", value: response?.data?.data },
          ])
        );

        localStorage.setItem("canopyAccess", response?.data?.access_token);
        axiosCanopyApi.defaults.headers.common["Authorization"] = `Bearer ${response?.data?.access_token}`;
      }
      return response;
    } catch (error) {
      console.log("----error------", error?.response?.status);
      dispatch(emsSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {canopyLoginAPI}
 */
export const canopyLoginAPI = () =>
  async (dispatch) => {
    const headers = {
      'Content-Type': 'application/json'
    }
    try {
      dispatch(emsSetState([{ key: "loading", value: true }]));
      const response = await axiosCanopyApi.post(`/login`, {
        "email": "jiehong.wong@durapowergroup.com",
        "password": "Durapower123!"
      }, {
        headers: headers
      });
      if (response) {
        dispatch(
          emsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "canopyLoginData", value: response?.data?.data },
          ])
        );

        localStorage.setItem("canopyAccess", response?.data?.access_token);
        localStorage.setItem("canopyRefresh", response?.data?.refresh_token);
        axiosCanopyApi.defaults.headers.common["Authorization"] = `Bearer ${response?.data?.access_token}`;
      }
      return response;
    } catch (error) {
      dispatch(emsSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {microgridsAPI}
 */
export const microgridsAPI = () =>
  async (dispatch) => {
    try {
      dispatch(emsSetState([{ key: "loading", value: true }]));
      const response = await axiosCanopyApi.get(`/microgrids`, null);
      if (response) {
        dispatch(
          emsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "microgridsData", value: response?.data?.microgrids },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(emsSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {setMicrogridID}
 */
export const setMicrogridID = (payload) =>
  async (dispatch) => {
    dispatch(emsSetState([{ key: "microgrid_id", value: payload }]));

  };

/**
 * @param {statusAPI}
 */
export const statusAPI = ({ id }) =>
  async (dispatch) => {
    try {
      dispatch(emsSetState([{ key: "loading", value: true }]));
      const response = await axiosCanopyApi.get(`/microgrids/${id}/status`, null);
      if (response) {
        dispatch(
          emsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "statusData", value: response?.data?.status },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(emsSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {eventsAPI}
 */
export const eventsAPI = ({ id, data }) =>
  async (dispatch) => {
    try {
      dispatch(emsSetState([{ key: "loading", value: true }]));
      const response = await axiosCanopyApi.post(`/microgrids/${id}/events`, data);
      if (response) {
        dispatch(
          emsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "eventsData", value: response?.data?.events },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(emsSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {comparisionChartAPI}
 */
export const comparisionChartAPI = ({ id, data }) =>
  async (dispatch) => {
    try {
      dispatch(emsSetState([{ key: "loading", value: true }]));
      const response = await axiosCanopyApi.post(`/microgrids/${id}/instantaneous`, data);
      if (response) {
        dispatch(
          emsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "comparisionChartData", value: response?.data?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(emsSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {tasksAPI}
 */
export const tasksAPI = ({ id, month }) =>
  async (dispatch) => {
    try {
      dispatch(emsSetState([{ key: "loading", value: true }]));
      const response = await axiosCanopyApi.get(`/microgrids/${id}/tasks/${month}`, null);
      if (response) {
        dispatch(
          emsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "tasksData", value: response?.data?.tasks },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(emsSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {solarPRChartAPI}
 */
export const solarPRChartAPI = ({ id, year, day, month }) =>
  async (dispatch) => {
    try {
      dispatch(emsSetState([{ key: "loading", value: true }]));
      const response = await axiosCanopyApi.get(`/microgrids/${id}/analytics/solar-pr?year=${year}&day=${day}&month=${month}`, null);
      if (response) {
        dispatch(
          emsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "solarPRChartData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(emsSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };


/**
 * @param {systemInfoImageAPI}
 */
export const systemInfoImageAPI = ({ id }) =>
  async (dispatch) => {
    try {
      dispatch(emsSetState([{ key: "loading", value: true }]));
      const response = await axiosApi.get(`/projects/get_project_image/${id}`, null);
      if (response) {
        dispatch(
          emsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "systemInfoImageData", value: response?.data?.image_url },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(emsSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {systemInfoMicrogridAPI}
 */
export const systemInfoMicrogridAPI = ({ id }) =>
  async (dispatch) => {
    try {
      dispatch(emsSetState([{ key: "loading", value: true }]));
      const response = await axiosCanopyApi.get(`/microgrids/${id}`, null);
      if (response) {
        dispatch(
          emsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "systemInfoMicrogridData", value: response?.data?.microgrid },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(emsSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {systemInfoAPI}
 */
export const systemInfoAPI = ({ id }) =>
  async (dispatch) => {
    try {
      dispatch(emsSetState([{ key: "loading", value: true }]));
      const response = await axiosCanopyApi.get(`/microgrids/${id}/system-info`, null);
      if (response) {
        dispatch(
          emsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "systemInfoData", value: response?.data?.system_info },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(emsSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {monitoringGraphAPI}
 */
export const monitoringGraphAPI = ({ url, data }) =>
  async (dispatch) => {
    try {
      dispatch(emsSetState([{ key: "loading", value: true }]));
      const response = await axiosCanopyApi.post(url, data);
      if (response) {
        dispatch(
          emsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "monitoringGraphData", value: response?.data?.system_info },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(emsSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {analyticsOverviewAPI}
 */
export const analyticsOverviewAPI = ({ id, interval, date }) =>
  async (dispatch) => {
    try {
      dispatch(emsSetState([{ key: "loading", value: true }]));
      const response = await axiosCanopyApi.get(`/microgrids/${id}/report?interval=${interval}&date=${date}`, null);
      if (response) {
        dispatch(
          emsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "analyticsOverviewData", value: response?.data?.report },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(emsSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {batteryPerformanceAPI}
 */
export const batteryPerformanceAPI = ({ id }) =>
  async (dispatch) => {
    try {
      dispatch(emsSetState([{ key: "loading", value: true }]));
      const response = await axiosCanopyApi.get(`/microgrids/${id}/battery-performance-info`, null);
      if (response) {
        dispatch(
          emsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "batteryPerformanceData", value: response?.data?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(emsSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {batteryWarrantyInfoAPI}
 */
export const batteryWarrantyInfoAPI = ({ id }) =>
  async (dispatch) => {
    try {
      dispatch(emsSetState([{ key: "loading", value: true }]));
      const response = await axiosCanopyApi.get(`/microgrids/${id}/battery-warranty-info`, null);
      if (response) {
        dispatch(
          emsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "batteryWarrantyInfoData", value: response?.data?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(emsSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {batteryWarrantyGraphAPI}
 */
export const batteryWarrantyGraphAPI = ({ id }) =>
  async (dispatch) => {
    try {
      dispatch(emsSetState([{ key: "loading", value: true }]));
      const response = await axiosCanopyApi.get(`/microgrids/${id}/battery-warranty-graph`, null);
      if (response) {
        dispatch(
          emsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "batteryWarrantyGraphData", value: response?.data?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(emsSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {batteryComparisonAPI}
 */
export const batteryComparisonAPI = ({ id }) =>
  async (dispatch) => {
    try {
      dispatch(emsSetState([{ key: "loading", value: true }]));
      const response = await axiosCanopyApi.get(`/microgrids/${id}/battery-comparison`, null);
      if (response) {
        dispatch(
          emsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "batteryComparisonData", value: response?.data?.report },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(emsSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {totalLossesPVAPI}
 */
export const totalLossesPVAPI = ({ id, year, month, component_id, component_type }) =>
  async (dispatch) => {
    try {
      dispatch(emsSetState([{ key: "loading", value: true }]));
      const response = await axiosCanopyApi.get(`/microgrids/${id}/analytics/pv-losses-overview?year=${year}&component_id=${component_id}&component_type=${component_type}&month=${month}`, null);
      if (response) {
        dispatch(
          emsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "totalLossesPVData", value: response?.data?.losses },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(emsSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {performanceRatioAPI}
 */
export const performanceRatioAPI = ({ id, year, month }) =>
  async (dispatch) => {
    try {
      dispatch(emsSetState([{ key: "loading", value: true }]));
      const response = await axiosCanopyApi.get(`/microgrids/${id}/analytics/solar-pr?year=${year}&month=${month}`, null);
      if (response) {
        dispatch(
          emsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "performanceRatioData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(emsSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {performanceRatioInverterAPI}
 */
export const performanceRatioInverterAPI = ({ id, url }) =>
  async (dispatch) => {
    try {
      dispatch(emsSetState([{ key: "loading", value: true }]));
      const response = await axiosCanopyApi.get(`/microgrids/${id}/analytics/solar-pr?${url}`, null);
      if (response) {
        dispatch(
          emsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "performanceRatioData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(emsSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {prBeforeAPI}
 */
export const prBeforeAPI = ({ id, url }) =>
  async (dispatch) => {
    try {
      dispatch(emsSetState([{ key: "loading", value: true }]));
      const response = await axiosCanopyApi.get(`/microgrids/${id}/analytics/solar-pr?${url}`, null);
      if (response) {
        dispatch(
          emsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "prBeforeData", value: response?.data },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(emsSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {pVPeakAPI}
 */
export const pVPeakAPI = ({ id }) =>
  async (dispatch) => {
    try {
      dispatch(emsSetState([{ key: "loading", value: true }]));
      const response = await axiosCanopyApi.get(`/microgrids/${id}/report/pv-peak`, null);
      if (response) {
        dispatch(
          emsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "pVPeakData", value: response?.data?.nominal_power_kw },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(emsSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };

/**
 * @param {pVLossesDetailsAPI}
 */
export const pVLossesDetailsAPI = ({ id, data }) =>
  async (dispatch) => {
    try {
      dispatch(emsSetState([{ key: "loading", value: true }]));
      const response = await axiosCanopyApi.post(`/microgrids/${id}/analytics/pv-losses-detail`, data);
      if (response) {
        dispatch(
          emsSetState([
            { key: "loading", value: false },
            { key: "error", value: {} },
            { key: "pVLossesDetailsData", value: response?.data?.losses },
          ])
        );
      }
      return response;
    } catch (error) {
      dispatch(emsSetState([{ key: "loading", value: false }]));
      return error?.response?.data;
    }
  };



// ** Create Slice
/**
 * @param {emsSlice}
 */
const emsSlice = createSlice({
  name: "ems",
  initialState: initialStates,
  reducers: {
    emsSetState: (state, { payload }) => {
      if (Array.isArray(payload)) {
        for (const obj of payload) {
          _.set(state, obj.key, obj.value);
        }
      } else {
        _.set(state, payload.key, payload.value);
      }
    },
  },
});

export const { emsSetState } = emsSlice.actions;

const { reducer } = emsSlice;

export default reducer;
